<template>
  <div class="dashboard-agent-wrapper">
    <div class="action-row">
      <table>
        <tr v-for="item in items" :key="item.name">
          <td><p>{{ item.label }}</p></td>
          <td><p @click="startService(item.name)" class="action">
            <i class="fas fa-play fa-2xs"></i>
          </p></td>
          <td><p @click="stopService(item.name)" class="action">
            <i class="fas fa-stop fa-2xs"></i>
          </p></td>
          <td><p @click="statusService(item.name)" class="action">
            <i class="fas fa-question fa-2xs"></i>
          </p></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import WidgetMixin from '@/mixins/WidgetMixin';

export default {
  mixins: [WidgetMixin],
  data() {
    return {
      lastResponse: null,
    };
  },
  computed: {
    apiKey() {
      return this.parseAsEnvVar(this.options.apiKey);
    },
    endpoint() {
      return 'https://dashboard.arnaudjacques.me/.myapi';
    },
    items() {
      return [
        { name: 'Fooocus', label: 'Fooocus' },
        { name: 'StableDiffusion', label: 'Auto1111' },
        { name: 'Ollama', label: 'Ollama' },
        { name: 'ImmichMachineLearning', label: 'Immich ML' },
      ];
    },
  },
  methods: {
    startService(name) {
      this.fetchData(`Start ${name}`);
    },
    stopService(name) {
      this.fetchData(`Stop ${name}`);
    },
    statusService(name) {
      this.fetchData(`Status ${name}`);
    },
    fetchData(message) {
      this.makeRequest(this.endpoint, { 'X-API-KEY': this.apiKey }, 'POST', message)
        .then((response) => {
          this.processData(response.data);
          this.$toasted.show(response.message, { className: response.success ? 'toast-success' : 'toast-error' });
        })
        .catch((dataFetchError) => {
          this.finishLoading();
          this.$toasted.show(`Unable to fetch data: ${dataFetchError}`, { className: 'toast-error' });
        })
        .finally(() => {
          this.finishLoading();
        });
    },
    processData(data) {
      this.lastResponse = data;
    },
    tooltip(content) {
      return {
        content, html: true, trigger: 'hover focus', delay: 250,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.dashboard-agent-wrapper {
  p {
    font-size: 1rem;
    margin: 0.5rem auto;
    color: var(--widget-text-color);
  }
  .action-row {
    width: 100%;
    max-height: 5rem;
    display: inline-table;
    border-radius: var(--curve-factor);
    margin: 1rem auto 0.5rem auto;
    overflow: hidden;
  }
  .action {
    cursor: pointer;
  }
  table {
    width: 100%;
  }
  td i.fas {
    font-size: 1em;
  }
}
</style>
